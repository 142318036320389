<template>
  <ContentWrapper>
  <template v-slot:title>
    <div class="game-title" v-if="gameDetails != null">
      {{gameDetails.name}}
      <ToolTip :defaultBlackIcon="false" :positionTop="true" style="margin-left:10px">
        <template v-slot:exchange>{{gameDetails.contract_spec.exchange}}</template>
        <template v-slot:product>{{gameDetails.contract_spec.product}}</template>
        <template v-slot:balance>{{gameDetails.currency}}{{gameDetails.initial_cash | moneyFormat}}</template>
        <template v-slot:initial-margin>{{gameDetails.currency}}{{gameDetails.contract_spec.initial_margin_val | moneyFormat}}</template>
        <template v-slot:maintenance-margin>{{gameDetails.currency}}{{gameDetails.contract_spec.maintenance_margin_val | moneyFormat}}</template>
        <template v-slot:duration>{{gameDetails.duration}}</template>
        <template v-slot:timer>{{gameDetails.timer}}</template>
      </ToolTip>
    </div>
  </template>
   <div class="game-wrapper">
      <Container>
        <router-view></router-view>
      </Container>
   </div>
  </ContentWrapper>
</template>

<script>
import Container from '@/components/Container';
import ToolTip from '@/components/ToolTip';

export default {
  components:{
    Container,
    ToolTip
  },
  data(){
    return{
      gameDetails: null
    }
  },
  mounted(){
    this.gameDetails = this.currentGameDetails
  },
  computed:{
    currentGameDetails(){
      return this.$store.getters.getCurrentGame;
    }
  },  
  watch:{
    currentGameDetails(data){
      this.gameDetails = data;
    }
  },
}
</script>

<style lang="scss" scoped>

  .content-wrapper{
    overflow: initial;
  }

  .content-container{
    max-width: unset;

    @media screen and (max-width:600px){
      padding: 0;
      height: calc(100vh - 60px - 58px)
    }

  }

  .game-wrapper{

    height: calc(100% - 78px);
    overflow: auto;

    @media screen and (max-width:600px){
      height: calc(100vh - 60px);
    }
  }

  .game-title{
    display: flex;
  }
</style>